.customer__popup {
  display: block;
  position: fixed;
  background-color: white;
  width: 300px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

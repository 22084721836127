@import url("./component/Sidebar/Sidebar.css");
@import url("./component/Sidebar/__grouplist/sidebar__grouplist.css");
@import url("./component/Sidebar/__button/sidebar__button.css");
@import url("./component/MainContent/content-wrapper.css");
@import url("./component/Customer/__wrapper/customer__wrapper.css");
@import url("./component/Customer/__title/customer__title.css");
@import url("./component/Customer/__form/customer__form.css");
@import url("./component/Customer/__groupfield/customer__groupfield.css");
@import url("./component/Customer/__label/customer__label.css");
@import url("./component/Customer/__button/customer__button.css");
@import url("./component/Customer/__input/customer__input.css");
@import url("./component/Customer/__table/customer__table.css");
@import url("./component/Customer/__search/customer__search.css");
@import url("./component/Customer/__name/customer__name.css");
@import url("./component/Customer/__overlay/customer__overlay.css");
@import url("./component/Customer/__popup/customer__popup.css");
@import url("./component/Login/__wrapper/login__wrapper.css");
@import url("./component/Login/__form/login__form.css");
@import url("./component/AddProduct/addproduct__wrapper.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

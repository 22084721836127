.customer__input {
  /* all: unset; */
  padding-block: 1px;
  box-sizing: border-box;
  padding-inline: 2px;
  width: 250px;
  height: 30px;
}
.p-datepicker-trigger {
  background: #64748b;
  border: 1px solid #64748b;
}
